import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { courses as api, shared } from '@codesass/api';
import { courses as types } from '@codesass/types';

import { useCallback, useEffect, useMemo, useState, MouseEvent } from 'react';

import { Timestamp } from 'firebase/firestore';

import Layout from '../Layout';

type CertificationExamStatusesState = types.CertificationExamStatus & {
  id: string;
};

type VerifyProps = {
  uid: string;
  courseSlug: types.Course['slug'];
  courseName: types.Course['title'];
  certificationTitle: types.CertificationExamLesson['certificationTitle'];
};

const CertExams = () => {
  const [certStatuses, setCertStatuses] = useState<
    CertificationExamStatusesState[]
  >([]);

  const verify = useCallback(
    ({ uid, courseSlug, courseName, certificationTitle }: VerifyProps) =>
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        api.verifyCertification({
          uid,
          courseSlug,
          courseName,
          certificationTitle,
        });
        const currentCertStatusIndex = certStatuses.findIndex(
          c => c.courseSlug === courseSlug
        );
        setCertStatuses([
          ...certStatuses.slice(0, currentCertStatusIndex),
          ...certStatuses.slice(currentCertStatusIndex + 1),
        ]);
      },
    [certStatuses]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'courseSlug',
        headerName: 'คอร์ส',
        width: 200,
      },
      {
        field: 'startedAt',
        headerName: 'เริ่มเมื่อ',
        width: 150,
        valueFormatter: ({ value }) =>
          shared.timestampToDate(value as Timestamp),
      },
      {
        field: 'doneAt',
        headerName: 'สิ้นสุดเมื่อ',
        width: 150,
        valueFormatter: ({ value }) =>
          shared.timestampToDate(value as Timestamp),
      },
      {
        field: 'type',
        headerName: 'สถานะ',
        valueFormatter: ({ value }) => {
          return {
            start: 'เริ่ม',
            done: 'เสร็จสิ้น',
            timeExceed: 'เกินเวลา',
          }[value as types.CertificationExamStatus['type']];
        },
        width: 100,
      },
      {
        field: 'uid',
        headerName: 'User ID',
        width: 100,
        renderCell: ({ value }) => (
          <Button
            color="inherit"
            onClick={() => navigator.clipboard.writeText(value)}
          >
            คัดลอก
          </Button>
        ),
      },
      {
        field: 'email',
        headerName: 'การดำเนินการ',
        width: 100,
        renderCell: ({
          row: { uid, courseSlug, courseName, certificationTitle },
        }: GridRenderCellParams<string, CertificationExamStatusesState>) => (
          <Button
            variant="contained"
            size="small"
            onClick={verify({
              uid,
              courseSlug,
              courseName,
              certificationTitle,
            })}
          >
            ตรวจสอบ
          </Button>
        ),
      },
    ],
    [verify]
  );

  const loadCertExams = useCallback(async () => {
    const statuses = await api.getUnVerifiedCertificationExamStatuses();

    setCertStatuses(
      statuses.map(s => ({ ...s, id: `${s.uid}-${s.courseSlug}` }))
    );
  }, []);

  useEffect(() => {
    loadCertExams();
  }, [loadCertExams]);

  return (
    <Layout>
      <Box m={2}>
        <Typography variant="h5" component="h1" align="center" mb={2}>
          รายการขอใบรับรองการผ่านหลักสูตร
        </Typography>
        <DataGrid
          columns={columns}
          rows={certStatuses}
          isRowSelectable={() => false}
          sx={{ height: 400 }}
        ></DataGrid>
      </Box>
    </Layout>
  );
};

export default CertExams;
